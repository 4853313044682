import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  ChevronDown,
  Edit,
  ListFilter,
  Download,
  Info,
  Trash,
} from "lucide-react";
import { IconPlus } from "../../assets/icon";
import Modal from "./Modal";
import ModalEdit from "./EditModal";
import SelectFilter from "./SelectFilter";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request"; // Assume you have a utility for making API requests

const DashboardContainer = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  max-width: ${(props) => props.size - 100}px;
  margin: 0 auto;
  background: #ffffff;
  padding: 2rem;
  overflow-y: auto;
  height: 100vh;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Select = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const SelectIcon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const SelectOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
`;

const SelectOption = styled.div`
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;

  &:hover {
    background: #b8bcf6;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #565e6c transparent;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #565e6c;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 12px;
`;

const TableCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin: 0 2px;
`;

const DownloadButton = styled(ActionButton)`
  background: #3498db;
  color: white;
  border-radius: 4px;
  padding: 6px 12px;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const BtnWarehouse = styled.button`
  background-color: #379ae6;
  color: white;
  padding: 7px;
  font-size: 9px;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #379ae6c4;
  }
`;

const Warehouse = () => {
  const { user } = SelectAuth();
  const { width } = useWindowSize();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [warehouseData, setWarehouseData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditId, setIsEditId] = useState(null);
  const [filters, setFilters] = useState({
    comand: "Команда",
    filter: "Фильтры",
  });
  const [openSelect, setOpenSelect] = useState(null);

  // Fetch warehouse data when the component loads
  useEffect(() => {
    const fetchWarehouseData = async () => {
      try {
        const response = await request.get("/base-expenses"); // Assume /base-expenses endpoint
        setTableData(response.data); // Assuming response.data contains warehouse items
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
      }
    };
    fetchWarehouseData();
  }, [isModalOpen, isModalEditOpen]);

  const handleSelectClick = (filterName) => {
    setOpenSelect(openSelect === filterName ? null : filterName);
  };

  const handleOptionSelect = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
    setOpenSelect(null);
  };

  const handleOpenFilter = () => setIsFilterOpen(true);
  const handleCloseFilter = () => {
    setIsFilterOpen(false);
    setIsEditModal(false);
  };

  const handleSearch = (searchParams) => {
    console.log("Search with:", searchParams);
  };

  const handleDelete = async (id) => {
    try {
      await request.delete(`/base-expenses/${id}`); // DELETE API request to remove item
      setTableData(tableData.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting warehouse item:", error);
    }
  };

  const handleEditWarehouse = (item) => {
    setWarehouseData(item);
    setIsEditId(item.id);
    console.log(item.id);
    
    setIsEditModal(true);
    setIsModalOpen(true);
  };

  const handleAddWarehouse = async (newData) => {
    try {
      const response = await request.post("/base-expenses", newData); // POST request to add new item
      setTableData([...tableData, response.data]); // Add the new item to the list
    } catch (error) {
      console.error("Error adding warehouse item:", error);
    }
  };

  const handleUpdateWarehouse = async (id, updatedData) => {
    try {
      const response = await request.put(`/base-expenses/${id}`, updatedData); // PUT request to update item
      const updatedItems = tableData.map((item) =>
        item.id === id ? response.data : item
      );
      setTableData(updatedItems); // Update the list with the edited item
    } catch (error) {
      console.error("Error updating warehouse item:", error);
    }
  };

  const renderSelect = (filterName, icon) => (
    <Select>
      <SelectButton onClick={() => handleSelectClick(filterName)}>
        <SelectIcon>{icon}</SelectIcon>
        {filters[filterName]} <ChevronDown size={16} />
      </SelectButton>
      {openSelect === filterName && (
        <SelectOptions>
          <SelectOption
            onClick={() => handleOptionSelect(filterName, "Option 1")}
          >
            Option 1
          </SelectOption>
          <SelectOption
            onClick={() => handleOptionSelect(filterName, "Option 2")}
          >
            Option 2
          </SelectOption>
          <SelectOption
            onClick={() => handleOptionSelect(filterName, "Option 3")}
          >
            Option 3
          </SelectOption>
        </SelectOptions>
      )}
    </Select>
  );

  return (
    <DashboardContainer size={width}>
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        onSubmit={isEditModal ? handleUpdateWarehouse : handleAddWarehouse} // Dynamic based on add or edit
        isEditModal={isEditModal}
        editId={isEditId}
        warehouseData={warehouseData}
      />
      <TopRow>
        {["owner"].includes(user?.role) ? (
          <AddButton
            onClick={() => {
              setIsModalOpen(true);
              setIsEditModal(false); // Set to false for new entry
            }}
          >
            Расходник <IconPlus />
          </AddButton>
        ) : (
          <div style={{ padding: "10px 20px" }}></div>
        )}
        <FiltersContainer>
          {renderSelect("comand", <ListFilter size={16} />)}
          <Select>
            <SelectButton onClick={handleOpenFilter}>
              <SelectIcon>
                <ListFilter size={16} />
              </SelectIcon>
              Фильтры <ChevronDown size={16} />
            </SelectButton>
          </Select>
          <SelectFilter
            isOpen={isFilterOpen}
            onClose={handleCloseFilter}
            onSearch={handleSearch}
          />
        </FiltersContainer>
      </TopRow>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Название</TableHeader>
              <TableHeader>Категория</TableHeader>
              <TableHeader>Статус</TableHeader>
              <TableHeader>Поставщик</TableHeader>
              <TableHeader>Цена ед</TableHeader>
              <TableHeader>Стоимость</TableHeader>
              <TableHeader>Всего</TableHeader>
              <TableHeader>Использовано</TableHeader>
              <TableHeader>Распределено</TableHeader>
              <TableHeader>Файл</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <Tr key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.category_expense_id}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.supplier}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>{item.cost}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.used}</TableCell>
                <TableCell>{item.distribute}</TableCell>
                <TableCell>
                  <DownloadButton>
                    <Download size={16} /> Скачать
                  </DownloadButton>
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ActionButton>
                      <Info size={16} color="#888" />
                    </ActionButton>
                    <ActionButton onClick={() => handleEditWarehouse(item)}>
                      <Edit size={16} color="#379AE6" />
                    </ActionButton>
                    <ActionButton onClick={() => handleDelete(item.id)}>
                      <Trash size={16} color="#e74c3c" />
                    </ActionButton>
                    <BtnWarehouse onClick={() => setIsModalEditOpen(true)}>
                      Распределить
                    </BtnWarehouse>
                  </div>
                </TableCell>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <ModalEdit
        onClose={() => setIsModalEditOpen(false)}
        isOpen={isModalEditOpen}
      />
    </DashboardContainer>
  );
};

export default Warehouse;
