import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Paperclip } from "lucide-react";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import { MessageEditIcon } from "../../assets/icon";

const MainContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.size - 100}px;
  padding-bottom: 3rem;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const PinnedHeader = styled.span`
  margin-left: 24px;
  margin-top: 20px;
  color: #171a1f;
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: block;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationCard = styled.div`
  width: 396px;
  height: 123px;
  //   margin: 16px 0 0 16px;
  background: ${(props) => (props.isSelected ? "#F3F4F6" : "#ffffff")};
  border: 0.1px solid ${(props) => (props.isSelected ? "#65d46a78" : "#ffffff")};
  //   border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  opacity: 0;
  animation: ${fadeIn} 400ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NotificationContent = styled.div`
  padding: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => props.color || "#f3f4f6"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f494a;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

const UserName = styled.span`
  color: #323842;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
`;

const ReplyText = styled.span`
  color: #6e7787;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const TimeStamp = styled.span`
  color: #9095a0;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;

const MessagePreview = styled.div`
  color: #171a1f;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;

const EmailPreview = styled.div`
  color: #323842;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2acccf;
  margin-left: 8px;
`;

const FileIcon = styled(Paperclip)`
  margin-left: 8px;
  color: #9095a0;
`;

const ChatWrapper = styled.div`
  color: #171a1f;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 11px;
`;

const UserDialogue = ({ setSelectChat, selectChat, userNameChat }) => {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [selectedCard, setSelectedCard] = useState(null);

  const notifications = [
    {
      id: 1,
      user: "JO",
      userName: "Хавьер Ортис",
      time: "45s",
      message: "Новый отчет готов",
      email: "Я загрузил ежемесячный отчет...",
      avatarColor: "#2acccf",
      hasAttachment: true,
      messages: [
        {
          id: 1,
          text: "Добрый день! Как идет подготовка к совещанию?",
          isUser: false,
          time: "02:00 PM",
        },
        {
          id: 2,
          text: "Здравствуйте! Все идет по плану, материалы готовы.",
          isUser: true,
          time: "02:05 PM",
        },
        {
          id: 3,
          text: "Замечательно, спасибо за оперативность!",
          isUser: false,
          time: "02:07 PM",
        },
        {
          id: 4,
          text: "Не за что, всегда рад помочь.",
          isUser: true,
          time: "02:10 PM",
        },
        {
          id: 5,
          text: "До встречи на совещании.",
          isUser: false,
          time: "02:12 PM",
        },
      ],
    },
    {
      id: 2,
      user: "АК",
      userName: "Анна Ковалева",
      time: "2m",
      message: "Вопрос по проекту",
      email: "Можем ли мы обсудить сроки...",
      avatarColor: "#faedbd",
      messages: [
        {
          id: 1,
          text: "Доброе утро! Все ли готово к презентации?",
          isUser: false,
          time: "08:30 AM",
        },
        {
          id: 2,
          text: "Доброе утро! Да, все материалы подготовлены.",
          isUser: true,
          time: "08:35 AM",
        },
        {
          id: 3,
          text: "Отлично, тогда встретимся в конференц-зале через час.",
          isUser: false,
          time: "08:37 AM",
        },
        {
          id: 4,
          text: "Хорошо, буду на месте вовремя.",
          isUser: true,
          time: "08:40 AM",
        },
        { id: 5, text: "До встречи!", isUser: false, time: "08:42 AM" },
      ],
    },
    {
      id: 3,
      user: "ИС",
      userName: "Игорь Смирнов",
      time: "10m",
      message: "Обновление статуса",
      email: "Задача #1234 завершена...",
      avatarColor: "#d7c8f0",
      hasAttachment: false,
      messages: [
        {
          id: 1,
          text: "Привет! Как прошла встреча с клиентом?",
          isUser: false,
          time: "09:00 AM",
        },
        {
          id: 2,
          text: "Здравствуйте! Встреча прошла отлично, клиент доволен.",
          isUser: true,
          time: "09:05 AM",
        },
        {
          id: 3,
          text: "Это замечательно! Когда планируете начать работу по проекту?",
          isUser: false,
          time: "09:07 AM",
        },
        {
          id: 4,
          text: "Начнем уже завтра утром.",
          isUser: true,
          time: "09:10 AM",
        },
        {
          id: 5,
          text: "Хорошо, держите меня в курсе.",
          isUser: false,
          time: "09:12 AM",
        },
      ],
    },
    {
      id: 4,
      user: "ЕП",
      userName: "Елена Петрова",
      time: "1h",
      message: "Приглашение на встречу",
      email: "Приглашаю вас на совещание...",
      avatarColor: "#f3d2c1",
      messages: [
        {
          id: 1,
          text: "Привет! Как продвигается работа над отчетом?",
          isUser: false,
          time: "10:00 AM",
        },
        {
          id: 3,
          text: "Отлично! Когда планируете отправить?",
          isUser: false,
          time: "10:07 AM",
        },
        {
          id: 4,
          text: "Думаю, смогу отправить его вам до конца дня.",
          isUser: true,
          time: "10:10 AM",
        },
        {
          id: 5,
          text: "Замечательно, буду ждать. Спасибо за оперативность!",
          isUser: false,
          time: "10:12 AM",
        },
      ],
    },
    {
      id: 5,
      user: "МВ",
      userName: "Максим Волков",
      time: "2h",
      message: "Новый клиент",
      email: "У нас появился новый клиент...",
      avatarColor: "#8ed1fc",
      hasAttachment: true,
      messages: [
        {
          id: 1,
          text: "Привет! Как продвигается работа над отчетом?",
          isUser: false,
          time: "10:00 AM",
        },
        {
          id: 2,
          text: "Здравствуйте! Отчет почти готов, заканчиваю последние правки.",
          isUser: true,
          time: "10:05 AM",
        },
        {
          id: 3,
          text: "Отлично! Когда планируете отправить?",
          isUser: false,
          time: "10:07 AM",
        },
        {
          id: 4,
          text: "Думаю, смогу отправить его вам до конца дня.",
          isUser: true,
          time: "10:10 AM",
        },
        {
          id: 5,
          text: "Замечательно, буду ждать. Спасибо за оперативность!",
          isUser: false,
          time: "10:12 AM",
        },
      ],
    },
    {
      id: 6,
      user: "ОН",
      userName: "Ольга Новикова",
      time: "3h",
      message: "Вопрос по бюджету",
      email: "Нужно обсудить распределение...",
      avatarColor: "#d4eafa",
      messages: [
        {
          id: 1,
          text: "Привет! Как продвигается работа над отчетом?",
          isUser: false,
          time: "10:00 AM",
        },
        {
          id: 2,
          text: "Здравствуйте! Отчет почти готов, заканчиваю последние правки.",
          isUser: true,
          time: "10:05 AM",
        },
        {
          id: 3,
          text: "Отлично! Когда планируете отправить?",
          isUser: false,
          time: "10:07 AM",
        },
        {
          id: 4,
          text: "Думаю, смогу отправить его вам до конца дня.",
          isUser: true,
          time: "10:10 AM",
        },
        {
          id: 5,
          text: "Замечательно, буду ждать. Спасибо за оперативность!",
          isUser: false,
          time: "10:12 AM",
        },
      ],
    },
    {
      id: 7,
      user: "ДК",
      userName: "Дмитрий Козлов",
      time: "5h",
      message: "Обновление системы",
      email: "Планируется обновление...",
      avatarColor: "#ffd5cd",
      hasAttachment: true,
      messages: [
        {
          id: 1,
          text: "Анна, добрый день! У меня есть вопрос по проекту.",
          isUser: true,
          time: "11:30 AM",
        },
        {
          id: 2,
          text: "Здравствуйте! Да, конечно, что именно вас интересует?",
          isUser: false,
          time: "11:35 AM",
        },
        {
          id: 3,
          text: "Мы можем обсудить сроки выполнения задачи №5?",
          isUser: true,
          time: "11:37 AM",
        },
        {
          id: 4,
          text: "Конечно, давайте обсудим. Какие у вас предложения?",
          isUser: false,
          time: "11:40 AM",
        },
      ],
    },
  ];

  const handleCardClick = ({ id, userName, messages }) => {
    setSelectedCard((prevSelected) => (prevSelected === id ? null : id));
    userNameChat(userName);
    selectChat(messages);
  };

  return (
    <>
      <ChatWrapper>
        <div style={{ marginLeft: "21px", fontSize: 26 }}>
          {["manager"].includes(user?.role) ? "Мои чаты" : "Чаты"}
        </div>
        <div
          style={{ marginRight: "10px" }}
          onClick={() => setSelectChat(false)}
        >
          <MessageEditIcon />
        </div>
      </ChatWrapper>
      <MainContainer size={height}>
        {notifications.map((notification, index) => (
          <NotificationCard
            key={notification.id}
            index={index}
            isSelected={selectedCard === notification.id}
            onClick={() => {
              setSelectChat(true);
              handleCardClick(notification);
            }}
          >
            <NotificationContent>
              <UserInfo>
                <Avatar color={notification.avatarColor}>
                  {notification.user}
                </Avatar>
                <UserName>
                  {notification.userName} <ReplyText>ответил вам</ReplyText>
                </UserName>
                <TimeStamp>{notification.time}</TimeStamp>
                <StatusCircle />
                {notification.hasAttachment && <FileIcon size={16} />}
              </UserInfo>
              <MessagePreview>{notification.message}</MessagePreview>
              <EmailPreview>{notification.email}</EmailPreview>
            </NotificationContent>
          </NotificationCard>
        ))}
      </MainContainer>
    </>
  );
};

export default UserDialogue;
