export const UserProfilTagIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none">
    <rect width={44} height={44} fill="#FEFAEB" rx={22} />
    <path
      stroke="#7A6108"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M14.286 12.571h15.428v18l-2.571-1.714-2.572 1.714L22 28.857l-2.571 1.714-2.572-1.714-2.571 1.714v-18ZM17.714 24.571H22M25.429 24.571h.857"
    />
    <path
      stroke="#7A6108"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M22 21.143A2.571 2.571 0 1 0 22 16a2.571 2.571 0 0 0 0 5.143Z"
    />
  </svg>
);

export const UserProfilTagIconStatus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none">
    <rect width={44} height={44} fill="#F6F3FC" rx={22} />
    <path
      stroke="#7B48CC"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M14.286 12.571h15.428v18l-2.571-1.714-2.572 1.714L22 28.857l-2.571 1.714-2.572-1.714-2.571 1.714v-18ZM17.714 24.571H22M25.429 24.571h.857"
    />
    <path
      stroke="#7B48CC"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M22 21.143A2.571 2.571 0 1 0 22 16a2.571 2.571 0 0 0 0 5.143Z"
    />
  </svg>
);

export const NotificationHeaderIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={28} fill="none">
    <path
      stroke="#6E7787"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.4}
      d="M21.75 13v-3a7 7 0 1 0-14 0v3c0 3.3-3 4.1-3 6 0 1.7 3.9 3 10 3s10-1.3 10-3c0-1.9-3-2.7-3-6Z"
    />
    <path
      fill="#6E7787"
      d="M14.75 24a38.81 38.81 0 0 1-2.855-.1 2.992 2.992 0 0 0 5.71 0c-.894.066-1.844.1-2.855.1Z"
    />
  </svg>
);

export const UserComandIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <g
      stroke={color}
      strokeMiterlimit={10}
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M8 26.808c0-5.044 6-4.035 6-6.052a2.982 2.982 0 0 0-.547-2.017A4.667 4.667 0 0 1 12 15.2a4.296 4.296 0 0 1 4-4.533 4.296 4.296 0 0 1 4 4.533 4.587 4.587 0 0 1-1.473 3.537A3.111 3.111 0 0 0 18 20.756c0 2.017 6 1.01 6 6.053 0 0-1.593 1.192-8 1.192s-8-1.193-8-1.193ZM7.315 21.29a31.176 31.176 0 0 1-2.016-.153 9.112 9.112 0 0 1-3.966-.996c0-5.044 6-4.034 6-6.052a2.983 2.983 0 0 0-.546-2.017 4.666 4.666 0 0 1-1.454-3.539 4.296 4.296 0 0 1 4-4.533 4.296 4.296 0 0 1 4 4.533M24.686 21.29a31.18 31.18 0 0 0 2.015-.153 9.113 9.113 0 0 0 3.966-.996c0-5.044-6-4.034-6-6.052a2.982 2.982 0 0 1 .546-2.017 4.668 4.668 0 0 0 1.454-3.539 4.296 4.296 0 0 0-4-4.533 4.296 4.296 0 0 0-4 4.533" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const UserSettingsIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M16 19.2a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4Z"
    />
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M24.8 16c0-.584-.058-1.167-.173-1.74l2.965-2.18-2.4-4.16-3.381 1.483a8.764 8.764 0 0 0-3.004-1.737L18.4 4h-4.8l-.407 3.666a8.764 8.764 0 0 0-3.003 1.737L6.808 7.92l-2.4 4.16 2.966 2.182a8.807 8.807 0 0 0 0 3.48L4.408 19.92l2.4 4.16 3.382-1.482a8.764 8.764 0 0 0 3.003 1.738L13.6 28h4.8l.407-3.666a8.764 8.764 0 0 0 3.004-1.737l3.381 1.483 2.4-4.16-2.965-2.182A8.847 8.847 0 0 0 24.8 16Z"
    />
  </svg>
);

export const UserCategoryIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.667 13.333h2.666c2.667 0 4-1.333 4-4V6.667c0-2.667-1.333-4-4-4H6.666c-2.666 0-4 1.333-4 4v2.666c0 2.667 1.334 4 4 4ZM22.666 13.333h2.667c2.667 0 4-1.333 4-4V6.667c0-2.667-1.333-4-4-4h-2.666c-2.667 0-4 1.333-4 4v2.666c0 2.667 1.333 4 4 4ZM22.666 29.333h2.667c2.667 0 4-1.333 4-4v-2.666c0-2.667-1.333-4-4-4h-2.666c-2.667 0-4 1.333-4 4v2.666c0 2.667 1.333 4 4 4ZM6.667 29.333h2.666c2.667 0 4-1.333 4-4v-2.666c0-2.667-1.333-4-4-4H6.666c-2.666 0-4 1.333-4 4v2.666c0 2.667 1.334 4 4 4Z"
    />
  </svg>
);

export const UserPackageIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m22 12.533-12-6.92M28 21.333V10.667a2.667 2.667 0 0 0-1.333-2.307l-9.334-5.333a2.667 2.667 0 0 0-2.666 0L5.333 8.36A2.667 2.667 0 0 0 4 10.667v10.666a2.666 2.666 0 0 0 1.333 2.307l9.334 5.333a2.666 2.666 0 0 0 2.666 0l9.334-5.333A2.667 2.667 0 0 0 28 21.333Z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.36 9.28 16 16.013 27.64 9.28M16 29.44V16"
    />
  </svg>
);

export const UserDownloadIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28 20v5.333A2.667 2.667 0 0 1 25.333 28H6.667A2.667 2.667 0 0 1 4 25.333V20M9.333 13.333 16 20l6.667-6.667M16 20V4"
    />
  </svg>
);

export const UserLinkIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M13.333 17.333a6.668 6.668 0 0 0 10.054.72l4-4a6.666 6.666 0 0 0-9.427-9.426l-2.293 2.28"
    />
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M18.667 14.667a6.668 6.668 0 0 0-10.054-.72l-4 4a6.666 6.666 0 0 0 9.427 9.426l2.28-2.28"
    />
  </svg>
);

export const UserAccountIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M18.4 10.8c0 1.988 1.612 4.4 3.6 4.4s3.6-2.412 3.6-4.4a3.6 3.6 0 0 0-7.2 0Z"
    />
    <path
      stroke={color}
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M22.4 23.2H28v-1.73c0-.978-.588-1.863-1.497-2.224A12.19 12.19 0 0 0 22 18.4c-1.92 0-3.463.431-4.503.846a2.358 2.358 0 0 0-1.043.814"
    />
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M19.2 25.6H4v-2.252a3.19 3.19 0 0 1 1.786-2.873C7.067 19.851 9.022 19.2 11.6 19.2s4.533.651 5.814 1.275a3.19 3.19 0 0 1 1.786 2.873V25.6ZM16 10.8c0 2.43-1.97 5.2-4.4 5.2-2.43 0-4.4-2.77-4.4-5.2a4.4 4.4 0 1 1 8.8 0Z"
    />
  </svg>
);

export const UserDocumentIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M26.286 3.429H5.714V28.57h20.572V3.43ZM19.429 9.143h2.285M19.429 13.714h2.285M10.286 18.286h11.428M10.286 22.857h11.428"
    />
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M14.857 9.143h-4.571v4.571h4.571V9.143Z"
    />
  </svg>
);

export const UserAnalyticsIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.92}
      d="M26.4 4.25H5.6v24h20.8v-24ZM16 12.25v8M12 17.85v2.4M20 15.45v4.8"
    />
  </svg>
);

export const UserMessagesIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none">
    <path
      stroke={color}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M26.286 5.071H5.714A2.285 2.285 0 0 0 3.43 7.357V19.93a2.285 2.285 0 0 0 2.285 2.285h5.715L16 27.93l4.571-5.715h5.715a2.285 2.285 0 0 0 2.285-2.285V7.357a2.285 2.285 0 0 0-2.285-2.286ZM10.286 10.786h11.428M10.286 16.5h6.857"
    />
  </svg>
);

export const NotificationCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 1 1 13M1 1l12 12"
    />
  </svg>
);

export const DashboarFilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <rect width={32} height={32} fill="#F3F4F6" rx={3} />
    <g
      stroke="#565E6C"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.029}
      clipPath="url(#a)"
    >
      <path d="M16 12.571h4.714M11.286 12.571h1.285M14.286 11.286h-1.714v2.571h1.714v-2.571ZM20.286 16h.428M11.286 16h5.571M18.572 14.714h-1.715v2.572h1.715v-2.572ZM16 19.429h4.714M11.286 19.429h1.285M14.286 18.143h-1.714v2.571h1.714v-2.571Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M10 10h12v12H10z" />
      </clipPath>
    </defs>
  </svg>
);

export const SortIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
    <path
      stroke="#171A1F"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.029}
      d="M2.572 4.714h6.857M1.286 2.143h9.428M3.857 7.286h4.286M5.143 9.857h1.714"
    />
  </svg>
);

export const KanbaInfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#535CE8"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.057}
      d="M12 21.428a9.429 9.429 0 1 0 0-18.857 9.429 9.429 0 0 0 0 18.857ZM12 11.143v5.143"
    />
    <path
      fill="#535CE8"
      d="M12 8.571a.857.857 0 1 0 0-1.714.857.857 0 0 0 0 1.714Z"
    />
  </svg>
);

export const KanbanCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
    <rect width={36} height={36} fill="#F3F4F6" rx={18} />
    <path
      stroke="#565E6C"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.886}
      d="m23.5 12.5-11 11M23.5 23.5l-11-11"
    />
  </svg>
);

export const SelectIconFilter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
    <path
      stroke="#171A1F"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.029}
      d="M2.571 4.714h6.857M1.286 2.143h9.429M3.857 7.286h4.286M5.143 9.857h1.714"
    />
  </svg>
);

export const TelegramIconLogin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none">
    <path
      fill="url(#a)"
      d="M24 45c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21Z"
    />
    <path
      fill="#fff"
      d="M34.48 15.313c.187-1.208-.962-2.162-2.036-1.69l-21.397 9.394c-.77.338-.714 1.505.085 1.76l4.413 1.405c.842.268 1.754.13 2.49-.379l9.948-6.873c.3-.207.627.22.37.484l-7.16 7.383c-.696.716-.558 1.93.278 2.454l8.017 5.027c.9.564 2.056-.002 2.225-1.089l2.767-17.876Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={24}
        x2={24}
        y1={3}
        y2={45}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37BBFE" />
        <stop offset={1} stopColor="#007DBB" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
    <path
      stroke="#535CE8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.667 1v9.333M1 5.667h9.333"
    />
  </svg>
);

export const MessageEditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
    <rect width={36} height={36} fill="#535CE8" rx={4} />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.371}
      d="M19.143 14 22 16.857"
    />
    <path
      stroke="#fff"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.371}
      d="m16.285 22.571-4 1.143 1.143-4 7.094-7.094a1.143 1.143 0 0 1 1.616 0l1.24 1.242c.447.446.447 1.17 0 1.615l-7.093 7.094Z"
    />
  </svg>
);
