import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Plus, SquarePen } from "lucide-react";
import Modal from "./Modal";
import ModalEmployee from "./ModalEmployee";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";
import { useNavigate } from "react-router-dom";
const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  font-family: "Lato", sans-serif;
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5rem;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  color: #535ce8;
  background: #e8e9fd;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #d1d3fc;
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background: #b8bcf6;
  }
`;

// const EditButton = styled(AddButton)`
//   background: #cacdf8;
//   border: 1px solid #cacdf8;
//   color: #535ce8;
//   &:hover {
//     background: #f0f1fc;
//   }
// `;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

const LoadingCell = styled.div`
  height: 20px;
  background: #f0f1f2;
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;


const TeamTable = () => {
  const { user } = SelectAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEmployee, setIsModalOpenEmployee] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch teams from the API when the component mounts
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setIsLoading(true);
        const response = await request.get("/teams"); // API call to fetch teams
        setTeams(response.data);
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [isModalOpen, isModalOpenEmployee]);

  // Handle adding a new team (open the modal)
  const handleAddTeam = () => {
    setIsModalOpen(true);
  };
 const navigate = useNavigate()
  // Handle adding an employee to a specific team
  const handleAddEmployee = (teamNumber) => {
    setIsModalOpenEmployee(true);
    navigate("/command?id=" + teamNumber)
    console.log(`Добавление сотрудника в команду ${teamNumber}`);
  };

  return (
    <MainContainer>
      {["owner"].includes(user?.role) && (
        <ButtonContainer>
          <AddButton onClick={handleAddTeam}>
            Команда <Plus size={16} />
          </AddButton>
        </ButtonContainer>
      )}
      <Table>
        <thead>
          <tr>
            <Th>Номер</Th>
            <Th>Команда</Th>
            <Th>Сотрудники</Th>
            <Th>Лид</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <tr key={index}>
                  <Td>
                    <LoadingCell style={{ width: "30px" }} />
                  </Td>
                  <Td>
                    <LoadingCell style={{ width: "100px" }} />
                  </Td>
                  <Td>
                    <LoadingCell style={{ width: "30px" }} />
                  </Td>
                  <Td>
                    <LoadingCell style={{ width: "120px" }} />
                  </Td>
                </tr>
              ))
            : teams.map((team) => (
                <Tr key={team.id}>
                  <Td>{team.id}</Td>
                  <Td>
                    {team.name}
                    <SquarePen
                      color="#379AE6"
                      cursor={"pointer"}
                      size={22}
                      onClick={handleAddTeam}
                      style={{ float: "right" }}
                    />
                  </Td>
                  <Td>{team.quantity}</Td>
                  <Td>{team.lead || 'Отсутствует'}</Td>
                  <Td>
                    <ActionButton
                      onClick={() => handleAddEmployee(team.id)}
                    >
                      Сотрудник <Plus size={16} />
                    </ActionButton>
                  </Td>
                </Tr>
              ))}
        </tbody>
      </Table>
      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
      <ModalEmployee
        onClose={() => setIsModalOpenEmployee(false)}
        isOpen={isModalOpenEmployee}
      />
    </MainContainer>
  );
};

export default TeamTable;
