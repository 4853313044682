import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { X, PlusCircle, Trash2 } from "lucide-react";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 26, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContainer = styled.div`
  width: 560px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
`;

const ModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin: 0;
`;

const CloseButton = styled.button`
  background: #f3f4f6;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #dee1e6;
  margin: 0;
`;

const FormContent = styled.div`
  padding: 24px;
  overflow-y: scroll;
  max-height: 600px;
  scrollbar-width: none;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const inputStyles = css`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #4a7dff;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const Select = styled.select`
  ${inputStyles}
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: #535ce8;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin-bottom: 16px;
`;

const ExpenseItem = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const ExpenseSelect = styled(Select)`
  flex: 1;
`;

const ExpenseInput = styled(Input)`
  width: 80px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  display: flex;
  align-items: center;
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const FormFieldAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextWraper = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 14px;
  display: block;
`;

const Modal = ({ onClose, isOpen, title }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: "22.07.2024",
      offer: "",
      name: "",
      comment: "",
      expenses: [{ category: "category1", item: "item1", quantity: "5" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "expenses",
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    console.log(data);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>
        </ModalHeader>
        <HorizontalLine />
        <FormContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label>Дата добавления</Label>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => <Input {...field} />}
              />
              {errors.date && (
                <ErrorMessage>{errors.date.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Оффер</Label>
              <Controller
                name="offer"
                control={control}
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => (
                  <Select {...field}>
                    <option value="">Выберите оффер</option>
                    <option value="offer1">Оффер 1</option>
                    <option value="offer2">Оффер 2</option>
                  </Select>
                )}
              />
              {errors.offer && (
                <ErrorMessage>{errors.offer.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Название</Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => <Input {...field} />}
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Комментарий</Label>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </FormField>

            <FormFieldAdd>
              <TextWraper>Добавить расходник</TextWraper>
              <AddButton
                type="button"
                onClick={() => append({ category: "", item: "", quantity: "" })}
              >
                <PlusCircle size={22} />
              </AddButton>
            </FormFieldAdd>

            {fields.map((field, index) => (
              <ExpenseItem key={field.id}>
                <Controller
                  name={`expenses.${index}.category`}
                  control={control}
                  rules={{ required: "Обязательно" }}
                  render={({ field }) => (
                    <ExpenseSelect {...field}>
                      <option value="">Категория</option>
                      <option value="category1">Категория 1</option>
                      <option value="category2">Категория 2</option>
                    </ExpenseSelect>
                  )}
                />
                <Controller
                  name={`expenses.${index}.item`}
                  control={control}
                  rules={{ required: "Обязательно" }}
                  render={({ field }) => (
                    <ExpenseSelect {...field}>
                      <option value="">Расходник</option>
                      <option value="item1">Расходник 1</option>
                      <option value="item2">Расходник 2</option>
                    </ExpenseSelect>
                  )}
                />
                <Controller
                  name={`expenses.${index}.quantity`}
                  control={control}
                  rules={{
                    required: "Обязательно",
                    min: { value: 1, message: "Минимум 1" },
                  }}
                  render={({ field }) => (
                    <ExpenseInput
                      type="number"
                      placeholder="Кол-во"
                      {...field}
                    />
                  )}
                />
                <DeleteButton type="button" onClick={() => remove(index)}>
                  <Trash2 size={20} />
                </DeleteButton>
              </ExpenseItem>
            ))}
            {errors.expenses && (
              <ErrorMessage>Все поля расходников обязательны</ErrorMessage>
            )}
          </form>
        </FormContent>
        <HorizontalLine />
        <ModalFooter>
          <SubmitButton
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Загрузка..." : "Добавить"}
          </SubmitButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
