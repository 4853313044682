import React, { useState } from "react";
import styled from "styled-components";
import { AtSign, Phone, Building, ChevronUp, ChevronDown } from "lucide-react";
import { UserProfilTagIcon, UserProfilTagIconStatus } from "../../assets/icon";
import AvatarUserIcon from "../../assets/image/AvatarProfil.png";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";

const ProfileWrapper = styled.section`
  width: 630px;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  padding: 24px;
  font-family: "Lato", sans-serif;
  overflow-y: scroll;
  height: ${(props) => props.size - 50}px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

// const AvatarStoryWraper = styled.li`
//   display: flex;
//   flex-direction: column;
//   text-align: center;
//   position: relative;
// `;

const UserAvatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 16px;
  cursor: pointer;
`;

const UserNameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #171a1f;
  margin-bottom: 4px;
`;

const UserNameRole = styled.div`
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535ce8;
  margin-bottom: 7px;
  background-color: #f1f2fd;
  text-align: center;
  padding: 5px 7px;
  border-radius: 4px;
`;

const UserId = styled.span`
  font-size: 14px;
  color: #6e7787;
  margin-left: 45px;
`;

const UserRole = styled.span`
  font-size: 14px;
  color: #000000;
  padding: 8px 8px;
  border: 1px solid #dee1e6;
  border-radius: 4px;
  display: inline-block;
  margin-top: 4px;
`;

const UserDetailsSection = styled.div`
  margin-bottom: 24px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: #323842;
`;

const DetailIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: #6e7787;
`;

const SectionTitle = styled.h3`
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #323842;
  margin-bottom: 16px;
`;

const ClientInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ClientInfoLabel = styled.span`
  font-size: 14px;
  color: #424955;
`;

const ClientInfoValue = styled.span`
  font-size: 14px;
  color: #171a1f;
  font-weight: 600;
`;

const AvatarUserId = styled.span`
  font-size: 12px;
  color: #6e7787;
  font-weight: 400;
`;

const ContainerAvatar = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  height: 35px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  font-size: 14px;
`;

const EmployeeCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  border: 1px solid #dee1e6;
  cursor: pointer;
`;

const EmployeeAvatar = styled.div`
  margin-right: 12px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmployeeInfo = styled.div`
  flex-grow: 1;
`;

const EmployeeName = styled.div`
  font-weight: 600;
`;

const EmployeeId = styled.div`
  font-size: 12px;
  color: #6e7787;
  text-align: center;
`;

const EmployeeUserId = styled.div`
  font-size: 12px;
  color: #6e7787;
`;

const EmployeeNameId = styled.div`
  font-size: 12px;
  color: #379ae6;
  background-color: #f1f8fd;
  padding: 4px 8px;
  border-radius: 17px;
  margin-bottom: 1px;
`;

const SaveButton = styled.button`
  width: auto;
  padding: 8px 16px;
  background-color: white;
  color: #4a7dff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  float: right;
`;

const SaveButtonUser = styled.button`
  width: auto;
  padding: 8px 16px;
  background-color: #f1f8fd;
  color: #379ae6;
  border: none;
  font-size: 14px;
  cursor: pointer;
  float: right;
  margin-top: -43px;
  border-radius: 15px;
`;

const StatusDropdown = styled.div`
  width: 60%;
  border: 1px solid #dee1e6;
  border-radius: 4px;
  background-color: #f9fafb;
  margin-bottom: 12px;
`;

const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
`;

const StatusOptions = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-top: 1px solid #dee1e6;
`;

const StatusOption = styled.label`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f0f1f3;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const DepositDiv = styled.div`
  width: 100%;
`;

const Divider = styled.hr`
  border: none;
  height: 0.5px;
  background-image: linear-gradient(to right, #e5e7eb 60%, transparent 60%);
  background-size: 6px 1px;
  background-repeat: repeat-x;
  margin: 24px 0;
`;

function StatusSelect() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Новый");

  const options = [
    "Новый",
    "В обработке",
    "Внести депозит",
    "Закрыт",
    "Отменен",
  ];

  return (
    <StatusDropdown>
      <StatusHeader onClick={() => setIsOpen(!isOpen)}>
        {selectedStatus}
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </StatusHeader>
      <StatusOptions isOpen={isOpen}>
        {options.map((option) => (
          <StatusOption key={option}>
            <Checkbox
              type="checkbox"
              checked={selectedStatus === option}
              onChange={() => setSelectedStatus(option)}
            />
            {option}
          </StatusOption>
        ))}
      </StatusOptions>
    </StatusDropdown>
  );
}

function StatusChange() {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("Антон Букин");

  const options = ["Антон Букин1", "Антон Букин2", "Антон Букин3"];

  return (
    <StatusDropdown>
      <StatusHeader onClick={() => setIsOpen(!isOpen)}>
        {selectedStatus}
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </StatusHeader>
      <StatusOptions isOpen={isOpen}>
        {options.map((option) => (
          <StatusOption key={option}>
            <Checkbox
              type="checkbox"
              checked={selectedStatus === option}
              onChange={() => setSelectedStatus(option)}
            />
            {option}
          </StatusOption>
        ))}
      </StatusOptions>
    </StatusDropdown>
  );
}

function UserProfile() {
  const { height } = useWindowSize();
  const { user } = SelectAuth();

  return (
    <ProfileWrapper size={height}>
      <ClientInfoItem>
        <ClientInfoLabel>
          <ContainerAvatar>
            <div>
              <UserAvatar src={AvatarUserIcon} alt="John Hill" />
            </div>
            <div>
              <UserNameBlock>
                <UserNameRole>Username</UserNameRole>
                <UserName>John Hill</UserName>
                <UserRole>Обработчик</UserRole>
              </UserNameBlock>
            </div>
          </ContainerAvatar>
        </ClientInfoLabel>
        <AvatarUserId>Id:123456</AvatarUserId>
      </ClientInfoItem>

      <Divider />
      <UserDetailsSection>
        <DetailItem>
          <DetailIcon>
            <Building size={16} />
          </DetailIcon>
          Username Telegram
        </DetailItem>
        <DetailItem>
          <DetailIcon>
            <Phone size={16} />
          </DetailIcon>
          Номер телефона
        </DetailItem>
        <DetailItem>
          <DetailIcon>
            <AtSign size={16} />
          </DetailIcon>
          E-mail Почта
        </DetailItem>
      </UserDetailsSection>

      {["owner"].includes(user?.role) ? (
        <div>
          <SectionTitle>Сменить обработчика</SectionTitle>
          <StatusChange />
          <SaveButtonUser>Сохранить</SaveButtonUser>
        </div>
      ) : null}

      <Divider />
      <div>
        <SectionTitle>КЛИЕНТ</SectionTitle>
        <ClientInfoItem>
          <ClientInfoLabel>ID Заявки</ClientInfoLabel>
          <ClientInfoValue>123456</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Дата регистрации</ClientInfoLabel>
          <ClientInfoValue>20.07.2024</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Оффер</ClientInfoLabel>
          <ClientInfoValue>Lorem ipsum</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Статус</ClientInfoLabel>
          <StatusSelect />
        </ClientInfoItem>
        <ClientInfoItem>
          <DepositDiv>
            <SectionTitle>Депозит</SectionTitle>
            <Input type="text" defaultValue="100 000 ₽" />
          </DepositDiv>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Площадка</ClientInfoLabel>
          <ClientInfoValue>Google</ClientInfoValue>
        </ClientInfoItem>
      </div>
      <Divider />
      <EmployeeCard>
        <EmployeeAvatar>
          <UserProfilTagIcon />
        </EmployeeAvatar>
        <EmployeeInfo>
          <EmployeeName>Обработчик</EmployeeName>
          <EmployeeUserId>John Hill</EmployeeUserId>
        </EmployeeInfo>
        <div>
          <EmployeeNameId>ID: 123456</EmployeeNameId>
          <EmployeeId>Username</EmployeeId>
        </div>
      </EmployeeCard>

      <EmployeeCard>
        <EmployeeAvatar>
          <UserProfilTagIconStatus />
        </EmployeeAvatar>
        <EmployeeInfo>
          <EmployeeName>Баер</EmployeeName>
          <EmployeeUserId>John Hill</EmployeeUserId>
        </EmployeeInfo>
        <div>
          <EmployeeNameId>ID: 123456</EmployeeNameId>
          <EmployeeId>Username</EmployeeId>
        </div>
      </EmployeeCard>
      {["owner"].includes(user?.role) ? (
        <SaveButton>Сохранить</SaveButton>
      ) : null}
    </ProfileWrapper>
  );
}

export default UserProfile;
