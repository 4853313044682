import React, { useCallback, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { X as CloseIcon, Bell as BellIcon } from "lucide-react";

const NotificationList = ({ isOpen, onClose }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const notifications = useMemo(
    () => [
      {
        id: 1147,
        status: "new",
        title: "Новое уведомление",
        date: "28.07.2024",
        description:
          "У вас новое важное уведомление. Пожалуйста, ознакомьтесь с деталями.",
      },
      {
        id: 1148,
        status: "processing",
        title: "Заказ в обработке",
        date: "27.07.2024",
        description:
          "Ваш заказ №1148 находится в обработке. Ожидайте обновления статуса.",
      },
      {
        id: 1149,
        status: "deposit",
        title: "Депозит внесен",
        date: "26.07.2024",
        description:
          "Ваш депозит успешно внесен. Средства будут доступны в течение 24 часов.",
      },
      {
        id: 1150,
        status: "closed",
        title: "Заказ закрыт",
        date: "25.07.2024",
        description:
          "Заказ №1150 успешно выполнен и закрыт. Спасибо за ваш выбор!",
      },
      {
        id: 1151,
        status: "cancelled",
        title: "Заказ отменен",
        date: "24.07.2024",
        description:
          "Заказ №1151 был отменен по вашему запросу. Свяжитесь с нами при возникновении вопросов.",
      },
    ],
    []
  );

  return (
    <CSSTransition
      in={isOpen}
      timeout={500}
      classNames="notification-list"
      unmountOnExit
    >
      <Container>
        <Header>
          <Title>
            <BellIcon size={20} />
            Уведомления
          </Title>
          <CloseButton onClick={handleClose}>
            <CloseIcon size={20} />
          </CloseButton>
        </Header>
        <NotificationsWrapper>
          {notifications.map((notification, index) => (
            <NotificationItem key={notification.id} index={index}>
              <StatusBadge status={notification.status}>
                {getStatusText(notification.status)}
              </StatusBadge>
              <NotificationContent>
                <NotificationTitle>{notification.title}</NotificationTitle>
                <NotificationMeta>
                  <MetaItem>#{notification.id}</MetaItem>
                  <MetaItem>{notification.date}</MetaItem>
                </NotificationMeta>
                <NotificationDescription>
                  {notification.description}
                </NotificationDescription>
              </NotificationContent>
            </NotificationItem>
          ))}
        </NotificationsWrapper>
      </Container>
    </CSSTransition>
  );
};

const getStatusText = (status) => {
  const statusMap = {
    new: "Новый",
    processing: "В обработке",
    deposit: "Внесен депозит",
    closed: "Закрыт",
    cancelled: "Отменен",
  };
  return statusMap[status] || "";
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  background: #ffffff;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  z-index: 1000;
  transition: all 500ms ease-in-out;

  &.notification-list-enter {
    opacity: 0;
  }

  &.notification-list-enter-active {
    opacity: 1;
  }

  &.notification-list-exit {
    transform: translateX(0%);
    opacity: 1;
  }

  &.notification-list-exit-active {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  opacity: 0;
  animation: ${fadeIn} 500ms ease-out forwards;
  animation-delay: 100ms;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;

  &:hover {
    color: #333;
  }
`;

const NotificationsWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  padding: 20px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const NotificationItem = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: box-shadow 0.2s;
  opacity: 0;
  animation: ${fadeIn} 500ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  cursor: pointer;
`;

const StatusBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;

  ${({ status }) => {
    const statusColors = {
      new: "#2196F3",
      processing: "#E68C37",
      deposit: "#2AC02F",
      closed: "#9E9E9E",
      cancelled: "#F44336",
    };
    return `background-color: ${statusColors[status] || "#9E9E9E"};`;
  }}
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
`;

const NotificationMeta = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
`;

const MetaItem = styled.span`
  font-size: 12px;
  color: #666;
`;

const NotificationDescription = styled.p`
  font-size: 14px;
  color: #444;
  line-height: 1.4;
  margin: 0;
`;

export default NotificationList;
