import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { X } from "lucide-react";
import { platforms } from "../../utils";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 5;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 26px;
  border-radius: 8px;
  width: 550px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #111827;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 16px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: #f3f4f6;
  font-size: 14px;
  color: #374151;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
`;

const Button = styled.button`
  background-color: #6366f1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  float: right;
  margin-bottom: 2rem;

  &:hover {
    background-color: #4f46e5;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 16px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
  background-color: #f3f4f6;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Modal = ({ isOpen, onClose }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    if (isOpen) {
      setLink("");
    }
  }, [isOpen]);

  const handleGenerate = () => {
    setLink("https://example.com/generated-link");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent isOpen={isOpen}>
        <CloseButton onClick={onClose}>
          <X size={20} />
        </CloseButton>
        <Title>Генерация ссылки</Title>
        <Label>Выберите площадку</Label>
        <Select>
          {platforms.map((item, i) => (
            <option value={item} key={i}>{item}</option>
          ))}
        </Select>
        <Button onClick={handleGenerate}>Генерировать</Button>
        <Label>Ваша ссылка</Label>
        <Input value={link} readOnly placeholder="Link here" />
        <ButtonContainer>
          <Button onClick={handleCopy}>Копировать</Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
