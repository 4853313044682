import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import request from "../../utils/request"; // Import axios request module

const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12);
  font-family: "Lato", sans-serif;
`;

const Title = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #171a1f;
  margin: 32px 0 20px 72px;
`;

const Table = styled.table`
  width: 1306px;
  margin: 20px 0 0 72px;
  border-collapse: collapse;
  margin-bottom: 5rem;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
`;

const ContainerWrapperLink = styled.div`
  overflow-y: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const LoaderTd = styled.td`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #379ae6;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const MyLinktable = () => {
  const [offers, setOffers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch offers from the API when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setIsLoading(true);
        const response = await request.get("/offers"); // Adjust endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, []);

  // Delete an offer via API
  const deleteOffer = async (index) => {
    try {
      const offerToDelete = offers[index];
      await request.delete(`/offers/${offerToDelete.id}`); // Adjust endpoint
      setOffers(offers.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  // Edit offer
  const editOffer = (index) => {
    setEditIndex(index);
    setIsModalOpen(true); // Open the modal for editing
  };

  return (
    <MainContainer>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ContainerWrapperLink>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Площадка</Th>
              <Th>Ссылка</Th>
              <Th>Дополнительно</Th>
              <Th>Действия</Th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <LoaderTd colSpan="5">Загрузка...</LoaderTd>
              </tr>
            ) : (
              offers.map((offer, index) => (
                <Tr key={index}>
                  <Td>{offer.id}</Td>
                  <Td>{offer.offerName}</Td>
                  <Td>{offer.link}</Td>
                  <Td>{offer.description}</Td>
                  <Td>
                    <div style={{ display: "flex" }}>
                      <ActionButton edit onClick={() => editOffer(index)}>
                        Редактировать
                      </ActionButton>
                      <ActionButton onClick={() => deleteOffer(index)}>
                        Удалить
                      </ActionButton>
                    </div>
                  </Td>
                </Tr>
              ))
            )}
          </tbody>
        </Table>
      </ContainerWrapperLink>
    </MainContainer>
  );
};

export default MyLinktable;
