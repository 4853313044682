import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Plus } from "lucide-react";
import Modal from "./Modal";
import request from "../../utils/request"; 
const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  font-family: "Lato", sans-serif;
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const Table = styled.table`
  width: 960px;
  // margin: 20px 0 0 72px;
  border-collapse: collapse;
  margin-bottom: 5rem;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
`;

const ContainerWrapperLink = styled.div`
  overflow-y: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;
  margin-bottom: 15px;
  &:hover {
    background: #b8bcf6;
  }
`;


const Consumables = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch categories from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Handle form submission to add or update a category
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editIndex !== null) {
        // Update the existing category
        await request.put(`/category-expenses/${offers[editIndex].id}`, data); // API call to update
        const newOffers = [...offers];
        newOffers[editIndex] = data;
        setOffers(newOffers);
        setEditIndex(null);
      } else {
        // Add a new category
        const response = await request.post("/category-expenses", data); // API call to create
        setOffers([...offers, response.data]);
      }
    } catch (error) {
      console.error("Error submitting category:", error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  // Handle deleting a category
  const deleteOffer = async (index) => {
    try {
      await request.delete(`/category-expenses/${offers[index].id}`); // API call to delete
      const newOffers = offers.filter((_, i) => i !== index);
      setOffers(newOffers);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  // Handle editing a category
  const editOffer = (index) => {
    setEditIndex(index);
    setIsModalOpen(true);
  };

  return (
    <MainContainer>
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        onSubmit={onSubmit}
        defaultValues={editIndex !== null ? offers[editIndex] : {}}
      />
      <ContainerWrapperLink>
        <AddButton onClick={() => setIsModalOpen(true)}>
          Категория <Plus size={16} />
        </AddButton>
        <Table>
          <thead>
            <tr>
              <Th>Номер</Th>
              <Th>Категория</Th>
              <Th>Статус</Th>
              <Th>Дополнительно</Th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <Td colSpan="4">Загрузка...</Td>
              </tr>
            ) : (
              offers.map((offer, index) => (
                <tr key={index}>
                  <Td>#{offer.id}</Td>
                  <Td>{offer.name}</Td>
                  <Td>active</Td>
                  <Td>
                    <ActionButton edit onClick={() => editOffer(index)}>
                      Редактировать
                    </ActionButton>
                    <ActionButton onClick={() => window.confirm("Are you sure to delete this item?") && deleteOffer(index)}>
                      Удалить
                    </ActionButton>
                  </Td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </ContainerWrapperLink>
    </MainContainer>
  );
};

export default Consumables;