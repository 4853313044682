import React from "react";
import styled from "styled-components";
import { TelegramIconLogin } from "../../assets/icon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

const TelegramIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 400px;
  height: 44px;
  background-color: #535ce8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6a4ee8;
  }
`;

const TelegramAuthComponent = ({ openDashboard }) => {
  return (
    <Container>
      <TelegramIcon>
        <TelegramIconLogin />
      </TelegramIcon>
      <Title>Чтобы попасть в CRM авторизуйтесь в нашем телеграмме</Title>
      <Subtitle>
        Зайдите в наш телеграм и нажмите кнопку старт. После этого вернитесь в
        CRM систему
      </Subtitle>
      <Button onClick={openDashboard}>Телеграм</Button>
    </Container>
  );
};

export default TelegramAuthComponent;
