import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LogOut } from "lucide-react";
import { sidebarItems } from "./sidebarItem";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";

const SidebarWrapper = styled.aside`
  min-width: 4.5rem;
  max-height: ${(props) => props.size + 90}px;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  display: flex;
  flex-direction: column;
  border-right: 1.5px solid #f8f9fa;
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  flex-grow: 1;
  /* overflow-y: scroll; */
  height: 100vh;
  margin-bottom: 10rem;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const SidebarItemStyled = styled(Link)`
  width: 56px;
  height: 56px;
  margin-bottom: 5px;
  background: ${(props) => (props.active ? "#f3f4f6" : "transparent")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;
  position: relative;

  &:hover {
    background: #f3f4f6;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  left: 70px;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1000;
`;

const LogoutItem = styled(SidebarItemStyled)`
  margin-top: 26px;
  margin-bottom: 20px;
`;

function SidebarItem({ icon: Icon, path, title }) {
  const location = useLocation();

  const isActive = location.pathname === path;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <SidebarItemStyled
      to={path}
      active={isActive}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Icon size={32} color={isActive ? "#000000" : "#6E7787"} />
      <Tooltip show={showTooltip}>{title}</Tooltip>
    </SidebarItemStyled>
  );
}

function Sidebar() {
  const navigate = useNavigate();
  const { height } = useWindowSize();
  const [showLogoutTooltip, setShowLogoutTooltip] = useState(false);
  const { user } = SelectAuth();

  const roleSidebarItems = {
    owner: sidebarItems,
    manager: sidebarItems.filter((item) =>
      ["", "messages", "analytics", "documents", "settings"].includes(
        item.path.slice(1)
      )
    ),
    buyer: sidebarItems.filter((item) =>
      ["", "analytics", "documents", "links", "downloads", "settings"].includes(
        item.path.slice(1)
      )
    ),
    team_lead: sidebarItems.filter((item) =>
      [
        "",
        "analytics",
        "documents",
        "links",
        "consumables/warehouse",
        "downloads",
        "command",
        "settings",
      ].includes(item.path.slice(1))
    ),
  };

  const SidebarItems = roleSidebarItems[user.role] || [];
  console.log(user, "userRole--");
  return (
    <SidebarWrapper size={height}>
      <SidebarNav>
        {SidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            path={item.path}
            title={item.title}
          />
        ))}
        <LogoutItem
          as="div"
          onMouseEnter={() => setShowLogoutTooltip(true)}
          onMouseLeave={() => setShowLogoutTooltip(false)}
          onClick={() => navigate("/login")}
        >
          <LogOut size={32} />
          <Tooltip show={showLogoutTooltip}>Выйти</Tooltip>
        </LogoutItem>
      </SidebarNav>
    </SidebarWrapper>
  );
}

export default Sidebar;
