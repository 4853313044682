import { LayoutDashboard } from "lucide-react";
import {
  UserComandIcon,
  UserSettingsIcon,
  UserCategoryIcon,
  UserPackageIcon,
  UserDownloadIcon,
  UserLinkIcon,
  UserAccountIcon,
  UserDocumentIcon,
  UserAnalyticsIcon,
  UserMessagesIcon,
} from "../../assets/icon";

export const sidebarItems = [
  { icon: LayoutDashboard, path: "/", title: "Дашборд" },
  { icon: UserMessagesIcon, path: "/messages", title: "Диалог" },
  { icon: UserAnalyticsIcon, path: "/analytics", title: "Статистика" },
  { icon: UserDocumentIcon, path: "/documents", title: "Задачи" },
  { icon: UserAccountIcon, path: "/users", title: "Пользователи" },
  { icon: UserLinkIcon, path: "/links", title: "Оффер" },
  { icon: UserDownloadIcon, path: "/downloads", title: "Залив" },
  {
    icon: UserPackageIcon,
    path: "/consumables/warehouse",
    title: "Склад расходников",
  },
  {
    icon: UserCategoryIcon,
    path: "/category/consumables",
    title: "Категории расходников",
  },
  { icon: UserComandIcon, path: "/command", title: "Команда" },
  { icon: UserSettingsIcon, path: "/settings", title: "Настройки" },
];
